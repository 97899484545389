import './init.scss'
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import globalAppUserStore, { APIStatusType } from '../stores/globalAppUserStore';
import AppUserManager from '../managers/AppUserManager';
import { useHookstate } from '@hookstate/core';
import globalAuthStateStore from '../stores/globalAuthStateStore';
import Loading from '../components/Loading';
import InitLayout from './layout/InitLayout';
import TOS from './TOS';
import ButtonControl from './controls/ButtonControl';

const Init = () => {
  const location = useLocation();
  const globalAuthState = useHookstate(globalAuthStateStore);
  const authState = globalAuthState.get();
  const globalAppUserState = useHookstate(globalAppUserStore);
  const getAppUserState = globalAppUserState.get();
  var appUserManager = new AppUserManager(globalAuthState, globalAppUserState);
  const [initUserPhase, setInitUserPhase] = useState(0);
  const { apiStatus } = getAppUserState;

  useEffect(() => {
    if (location?.pathname &&
      !location?.pathname?.toLowerCase()?.includes('admin/content/edit') &&
      !location?.pathname?.toLowerCase()?.includes('admin/content/addnew')
    ) {
      sessionStorage.removeItem('FileUpload-content');
      sessionStorage.removeItem('FileUpload-displayContent');
      sessionStorage.removeItem('screen-data');
    }
  }, [location?.pathname]);

  useEffect(() => {
    if (APIStatusType.UserSetup == apiStatus && initUserPhase === 0) {
      setInitUserPhase(1);
      setTimeout(() => { setInitUserPhase(2); }, 3000);
    }
    else if (APIStatusType.TOSNotFound == apiStatus && initUserPhase === 0) {
      setInitUserPhase(2);
    }
  }, [apiStatus]);

  useEffect(() => {
    appUserManager.initialize();
  }, [authState, appUserManager]);

  const onSubmitNewAccount = (id) => {
    setInitUserPhase(4);
    appUserManager.setupUserProfile(id).then((r) => {
      window.location.href = '/profile';
    }).catch(e => {
      setInitUserPhase(999);
    });
  }


  const onSubmitTOS = (id) => {
    setInitUserPhase(5);
    appUserManager.updateTOS(id).then((r) => {
      window.location.href = appUserManager.defaultRoute();
    }).catch(e => {
      setInitUserPhase(999);
    });
  }

  const onCancelTOS = () => {
    setInitUserPhase(3);
  }

  let render = null;
  switch (apiStatus) {
    case APIStatusType.Startup:

      render = <InitLayout><div className='init__content'>&nbsp;</div></InitLayout>;
      break;
    case APIStatusType.AccessDenied:
      render = <InitLayout><div className='init__content'><h3>Your account is not active.</h3><h4><i>This can be caused by a deactivation of your user, school or district. Please contact Customer Support for more information.</i></h4></div></InitLayout>;
      break;
    // case APIStatusType.InitFailed:
      // render = <InitLayout><div className='init__content'><h4>An error has occurred while loading the page.</h4></div></InitLayout>;
      // break;
    case APIStatusType.InitFailed:
    case APIStatusType.Offline:
      render = <InitLayout><div className='init__content'><h4>Due to higher-than-normal levels of activity in EE PASS, the page may take a little longer to load. <br />Please refresh your web browser and try accessing the system again.</h4><ButtonControl onClick={() => { window.location.href = location.pathname; }}>Reload</ButtonControl><p>Contact <a href="mailto:support@niet.org?subject=EEPASSv2+Support">support@niet.org</a> if additional assistance is needed. </p></div></InitLayout>;
      break;
    case APIStatusType.Initializing:
      render = <InitLayout><div className='init__content'><Loading type='default' size='5rem' /><h5>Initializing...</h5></div></InitLayout>;
      break;
    case APIStatusType.OnlineSecure:
      render = <InitLayout><div className='init__content'><Loading type='default' size='5rem' /><h5>Initializing...</h5></div></InitLayout>;
      break;
    case APIStatusType.TOSNotFound:
      if (initUserPhase === 2) {
        render = <InitLayout><div className='init__content'><TOS onSubmit={onSubmitTOS} onCancel={onCancelTOS} /></div></InitLayout>;
        break;
      }
      else if (initUserPhase === 3) {
        render = <InitLayout><div className='init__content'><h4>You have rejected the terms of service. Please contact customer support if you have any questions or need additional information.</h4><ButtonControl onClick={() => { setInitUserPhase(2) }}>Review Terms</ButtonControl></div></InitLayout>;
        break;
      }
      else if (initUserPhase === 5) {
        render = <InitLayout><div className='init__content'><Loading type='default' size='5rem' /><h5>Updating your Terms of Service...</h5></div></InitLayout>;
        break;
      }
    case APIStatusType.UserSetup:
      if (initUserPhase === 1) {
        render = <InitLayout><div className='init__content'><Loading type='default' size='5rem' /><h5>Welcome {authState?.idTokenClaims?.name}! Registering your account...</h5></div></InitLayout>;
        break;
      }
      else if (initUserPhase === 2) {
        render = <InitLayout><div className='init__content'><TOS onSubmit={onSubmitNewAccount} onCancel={onCancelTOS} /></div></InitLayout>;
        break;
      }
      else if (initUserPhase === 3) {
        render = <InitLayout><div className='init__content'><h4>You have rejected the terms of service. Please contact customer support if you have any questions or need additional information.</h4><ButtonControl onClick={() => { setInitUserPhase(2) }}>Review Terms</ButtonControl></div></InitLayout>;
        break;
      }
      else if (initUserPhase === 4) {
        render = <InitLayout><div className='init__content'><Loading type='default' size='5rem' /><h5>Finalizing your registration...</h5></div></InitLayout>;
        break;
      }
      else if (initUserPhase === 999) {
        render = <InitLayout><div className='init__content'><h4>An error has occurred while registering your account. Please contact customer support.</h4></div></InitLayout>;
        break;
      }
    default:
      render = null;
      break;
  }

  const handleRender = () => {
    if (apiStatus === APIStatusType.OnlineSecure && !getAppUserState.loadingData) {
      return
    } else {

      return <div className="init__blockUi">{render}</div>
    }
  }

  return (<>
    {(apiStatus === APIStatusType.Initializing || apiStatus === APIStatusType.OnlineSecure || apiStatus === APIStatusType.Startup || apiStatus === APIStatusType.Offline ||
      apiStatus === APIStatusType.InitFailed || apiStatus === APIStatusType.AccessDenied || apiStatus === APIStatusType.UserSetup || apiStatus === APIStatusType.TOSNotFound) && <>{handleRender()}</>}
  </>);
}

export default Init;