import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import './ObservationReviewPrint.scss';
import { useObservationManager, useSurveyManager } from '../../../hooks/useManagers';
import moment from 'moment';
import PrintLayout from '../../../components/layout/PrintLayout';
import { FormatUTCDate, FormatUTCDateTime, FormatTimeNumber, FormatUTCDateTimeLong } from '../../../utilities/DateFormater';
import FileUploaderControl from '../../../components/controls/FileUploaderControl';
import documentIcon from '../../../content/icons/document.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhotoFilm } from '@fortawesome/free-solid-svg-icons';

const ObservationReviewPrint = ({ isDetailed }) => {
    const observationMgr = useObservationManager();
    const [loading, setLoading] = useState(false);
    const [print, setPrint] = useState(null);
    const [errors, setErrors] = useState(null)
    const [selectedObservation, setSelectedObservation] = useState(null);
    const [observationConfig, setObservationConfig] = useState(null);
    const [reviewData, setReviewData] = useState(null);
    const [surveySections, setSurveySections] = useState(null);
    const [numMedia, setNumMedia] = useState(null);
    const [mediasLoaded, setMediasLoaded] = useState([]);
    const [mediaLoadingComplete, setMediaLoadingComplete] = useState(false);

    const [observeeData, setObserveeData] = useState({
        observee: '',
        observeDate: '',
        address: '',
        observerInfo: '',
    })

    const params = useParams();

    const loadData = async (observationId) => {
        const promises = [];
        promises.push(
            observationMgr.getObservationReviewRatings(observationId).then(orr => {
            if (orr.Success) {
                setReviewData(orr.Items.first());
            } else {
                setErrors(orr.MessageDetails ?? ["Unknown Error"])
            }})
        );

        promises.push(
            observationMgr.getObservationSurveySectionsForReview(observationId, true).then(ssr => {
            if (ssr.Success) {
                let numberOfMedia = 0;
                ssr.Items.first().SurveySection?.Sections.forEach(sec => {
                    if (sec.SurveySectionType === 1) {
                        sec.Tags.forEach(t => {
                            if (t.ContentType === 2 && t.RubricIndicators?.length > 0 && getMediaType(t.Content) === 'img') {
                                numberOfMedia++;
                            }
                        })
                    }
                })
                setNumMedia(numberOfMedia);
                if (numberOfMedia === 0 || !isDetailed) {
                    setMediaLoadingComplete(true);
                }
                setSurveySections(ssr.Items.first());
            } else {
                setErrors(ssr.MessageDetails ?? ["Unknown Error"])
            }})
        );

        // Run all promises concurrently
        Promise.all(promises).catch(err => {
            console.error("Error loading data for observationId " + observationId + ":", err);
        });
    }


    useEffect(() => {
        if (!selectedObservation && params?.observationId && observationMgr && !observationMgr?.selectedObservation) {
            setLoading(true);
            observationMgr.get(params?.observationId).then(async (r) => {
                if (r.Success) {
                    setSelectedObservation(r.Items.first());

                    const promises = [];
                    promises.push(loadData(params?.observationId));
                    promises.push(observationMgr.observationConfigOperation(r.Items.first().ClientId, r.Items.first().DistrictId, r.Items.first().SchoolId, r.Items.first().SchoolYearIdentifier)
                                        .then(obsConfig => setObservationConfig(obsConfig))
                                );

                    setObserveeData({
                        observee: r.Items.first()?.Observed?.FullName,
                        observeDate: r.Items.first()?.PlannedObservationDateStart,
                        address: `${r.Items.first()?.School?.Name} - Grades: ${r.Items.first()?.GradeLevelsData?.trimStart(',')} (${r.Items.first()?.SubjectsTaughtData?.trimStart(',')})`,
                        observerInfo: `Observed by ${r.Items.first()?.Observer?.FullName} (via ${r.Items.first().Rubric?.Name} Rubric)`,
                        observer: r.Items.first()?.Observer?.FullName,
                        isLegacy: r.Items.first().LegacyReferenceId ? true : false
                    })

                    // Run all promises concurrently
                    Promise.all(promises).catch(err => {
                        console.error("Error loading data for observationId " + params?.observationId + ":", err);
                    });
                } else {
                    setErrors(r.MessageDetails ?? ["Unknown Error"])
                }
                setLoading(false);
            });
        }
        else if (observationMgr?.selectedObservation) {
            loadData(observationMgr.selectedObservation.observationId)
            setSelectedObservation(observationMgr.selectedObservation);
            observationMgr.observationConfigOperation(observationMgr.selectedObservation.first().ClientId, observationMgr.selectedObservation.first().DistrictId, observationMgr.selectedObservation.first().SchoolId, observationMgr.selectedObservation.first().SchoolYearIdentifier).then(obsConfig => {
                setObservationConfig(obsConfig);
                setObserveeData({
                    observee: observationMgr.selectedObservation.Observed?.FullName,
                    observeDate: observationMgr.selectedObservation?.PlannedObservationDateStart,
                    address: `${observationMgr.selectedObservation?.School?.Name} - Grades: ${observationMgr.selectedObservation?.GradeLevelsData?.trimStart(',')} (${observationMgr.selectedObservation?.SubjectsTaughtData?.trimStart(',')})`,
                    observerInfo: `Observed by ${observationMgr.selectedObservation?.Observer?.FullName} (Via ${observationMgr.selectedObservation.Rubric?.Name} Rubric)`
                })
                setLoading(false);
            });
        }
    }, [observationMgr, params?.observationId]);

    useEffect(() => {
        if (mediaLoadingComplete && observationConfig) {
            setPrint(moment().toISOString());
        }
    }, [mediaLoadingComplete, observationConfig])

    const mediaLoaded = () => {
        let mL = mediasLoaded;
        mL.push(1);
        setMediasLoaded(mL);
        if (mL.length === numMedia) {
            setMediaLoadingComplete(true);
        }
    }

    const getMediaType = (fileUrl) => {
        if (fileUrl?.toLowerCase().includes('.jpg') || fileUrl?.toLowerCase().includes('.jpeg') || fileUrl?.toLowerCase().includes('.png') || fileUrl?.toLowerCase().includes('.gif') || fileUrl?.toLowerCase().includes('.tiff')) {
            return 'img';
        }
        else if (fileUrl?.toLowerCase().includes('manifest')) {
            return 'vid';
        }
        else {
            return 'doc'
        }
    }

    const getRefinementRefinement = (isSelf, surveySectionType) => {
        if (surveySections) {
            let sections = surveySections?.SurveySection?.Sections ?? [];
            if (isSelf) {
                sections = surveySections?.SelfReflectionSurveySection?.Sections ?? [];
            }
            let section = _.find(sections, x => x.SurveySectionType === surveySectionType);
            return { name: section?.Tags?.first()?.RubricIndicators?.first().Name, details: section?.Evidence ?? '' };
        }
        else {
            return { name: '', details: '' }
        }
    }

    const evidencesCache = {};
    const getEvidenceByIndicator = (indicatorId) => {
        if (evidencesCache[indicatorId]) {
            return evidencesCache[indicatorId];
        }
        else if (surveySections) {
            let sections = surveySections?.SurveySection?.Sections ?? [];
            let noteSections = _.filter(sections, x => x.SurveySectionType === 1);
            const evidences = [];
            _.each(noteSections, noteSection => {
                let evidence = {
                    title: noteSection.SectionTitle,
                    time: FormatTimeNumber(noteSection.Time),
                    timeValue: noteSection.Time,
                    content: []
                }

                var evidenceFound = false;

                _.each(noteSection.Tags, tag => {
                    const indicator = _.find(tag.RubricIndicators, ri => ri.TagId == indicatorId);
                    if (indicator) {
                        const updateEvidence = _.find(evidences, x => x.timeValue === noteSection.Time && x.title === noteSection.SectionTitle && x.id === indicatorId);
                        if (updateEvidence) {
                            evidence = updateEvidence;
                            evidenceFound = true;
                        }
                        else {
                            evidence.name = indicator.Name;
                            evidence.id = indicatorId;
                            evidence.shortName = indicator.ShortName;
                            //evidence.content = [];
                        }

                        if (tag.ContentType == 2) {
                            evidence.content.push({ fileId: tag.FileId, fileUrl: tag.Content });
                        }
                        else {
                            evidence.content.push({ content: tag.Content });
                        }
                    }
                });



                if (evidence.name && !evidenceFound)
                    evidences.push(evidence);
            });

            evidencesCache[indicatorId] = _.orderBy(evidences, x => x.timeValue);
            return evidencesCache[indicatorId];
        }
        else {
            return [];
        }
    }

    //Handle update of last printed date for the observation...
    return (<>
        <PrintLayout print={print} errors={errors}>
            {!loading && <div className='print-report'>
                <div className='report-header'>
                    Observation Summary Report
                </div>
                <div className={`observation-subheader`}>
                    <div className={'observee-area'}>
                        <div className={'observee-banner'}>
                            <div className={'report-subheader'}>Report for {observeeData?.observee}</div>
                        </div>
                        <div className={'observee-metadata'}>
                            <div>{observeeData?.isLegacy ? moment(observeeData?.observeDate).format('MMMM D, YYYY @ h:mm A') : FormatUTCDateTimeLong(observeeData?.observeDate)}</div>
                            <div>{observeeData?.address}</div>
                            <div>{observeeData?.observerInfo}</div>
                        </div>
                    </div>
                </div>
                <div className='report-body'>
                    <div className={'report-subheader'}>Ratings</div>
                </div>
                {_.map(reviewData?.RubricDomains, (rd, i) =>
                    <div key={`rd_${i}_${rd.Id}`} className='report-section'>
                        <div className='report-domain-text'>
                            {rd.DomainName}
                        </div>
                        <div className='report-domain-grid'>
                            <div className='grid-header'>
                                <div className='name'>Indicator</div>
                                {!observationMgr?.disableSelfReflection(observationConfig, selectedObservation?.ObservationType < 3) && <div className='rating'>Self-Reflection<br />Ratings</div>}
                                <div className='rating'>Observer<br />Ratings</div>
                                <div className='desc'>Observer Rating Descriptor</div>
                            </div>
                            <div className='grid-body'>
                                {_.map(_.sortBy(rd.RubricIndicatorRatings, (o) => { return o.IndicatorSequence }), (rir, i) => <div key={`rir_${i}`}>
                                    <div className={`grid-row ${((i % 2) > 0) ? 'row-odd' : 'row-even'}`}>
                                        <div className='name'>{rir.IndicatorName} ({rir.IndicatorShortName})</div>
                                        {!observationMgr?.disableSelfReflection(observationConfig, selectedObservation?.ObservationType < 3) && <div className='rating'>{rir.SelfReflectionValue}</div>}
                                        <div className='rating'>{rir.ObservationValue}</div>
                                        <div className='desc'>{rir.ShortDescription}</div>
                                    </div>
                                    {isDetailed && <>
                                        {getEvidenceByIndicator(rir.RubricIndicatorId).length === 0 ?
                                            < div key={`es1_${i}`} className='evidence-section'>

                                                <div className={'evidence-none'}>
                                                    No evidence recorded for this rating.
                                                </div>
                                            </div> :
                                            <div key={`es2_${i}`} className='evidence-section'>
                                                {_.map(getEvidenceByIndicator(rir.RubricIndicatorId), (ebi, i) => {
                                                    var numDocs = 0;
                                                    var numVids = 0;
                                                    return (<div key={`ebi_${i}`} className={'evidence'}>
                                                        <div className='evidence-title'>{ebi.time}&nbsp;-&nbsp;{ebi.title}</div>
                                                        <div>{_.map(ebi.content, (c, i) => c.content ? <div key={`c_${i}`}>{c.content}</div> : null)}</div>
                                                        <div style={{ display: 'flex' }}>{_.map(ebi.content, (c, i) => {
                                                            if (c.fileUrl) {
                                                                var mediaType = getMediaType(c.fileUrl);

                                                                if (mediaType === 'doc') {
                                                                    numDocs++;
                                                                }
                                                                else if (mediaType === 'vid') {
                                                                    numVids++;
                                                                }
                                                            }
                                                            return (c.fileUrl
                                                                ? (
                                                                    mediaType === 'img' ?
                                                                        <img onLoad={() => mediaLoaded()} key={`c_m_${i}`} className={'print-detail-image-evidence'} src={c.fileUrl} />
                                                                        :
                                                                        null
                                                                )
                                                                : null)
                                                        })}
                                                            {numDocs > 0 && <div style={{ backgroundImage: `url(${documentIcon})` }} className={'print-detail-image-evidence'}><div className={'cnt'}>{numDocs}</div></div>}
                                                            {numVids > 0 && <div className={'print-detail-image-evidence flex-it'}><FontAwesomeIcon className={'fa-icon'} icon={faPhotoFilm} /><div className={'cnt'}>{numVids}</div></div>}
                                                        </div>
                                                        <div></div>
                                                    </div>)
                                                })
                                                }
                                            </div>
                                        }
                                    </>
                                    }
                                </div>)}
                            </div>
                        </div>
                    </div>)}
                {(getRefinementRefinement(false, 3).name || getRefinementRefinement(false, 2).name) &&
                    <div className='report-summary'>
                        <div className='report-subheader'>Report Observation Summary</div>
                        {getRefinementRefinement(false, 3).name &&
                            <div>
                                <div className='subheader'>
                                    <div className='subheader-text'>Reinforcement</div><div>({getRefinementRefinement(false, 3).name})</div>
                                </div>
                                <div className='text'>
                                    {getRefinementRefinement(false, 3).details}
                                </div>
                            </div>}
                        {getRefinementRefinement(false, 2).name &&
                            <div>
                                <div className='subheader'>
                                    <div className='subheader-text'>Refinement</div><div>({getRefinementRefinement(false, 2).name})</div>
                                </div>
                                <div className='text'>
                                    {getRefinementRefinement(false, 2).details}
                                </div>
                            </div>}
                    </div>}
                {((!observationMgr?.disableSelfReflection(observationConfig, selectedObservation?.ObservationType < 3) && (getRefinementRefinement(true, 3).name || getRefinementRefinement(true, 2).name))) &&

                    <div className='report-summary'>
                        <div className='report-subheader'>Report Self-Reflection Summary</div>
                        {getRefinementRefinement(true, 3).name &&
                            <div>
                                <div className='subheader'>
                                    <div className='subheader-text'>Reinforcement</div><div>({getRefinementRefinement(true, 3).name})</div>
                                </div>
                                <div className='text'>
                                    {getRefinementRefinement(true, 3).details}
                                </div>
                            </div>}
                        {getRefinementRefinement(true, 2).name &&

                            <div>
                                <div className='subheader'>
                                    <div className='subheader-text'>Refinement</div><div>({getRefinementRefinement(true, 2).name})</div>
                                </div>
                                <div className='text'>
                                    {getRefinementRefinement(true, 2).details}
                                </div>
                            </div>}
                    </div>
                }
                {/*selectedObservation?.ManualSignature && !isDetailed && <div className='signature-message'>{'Signature on Next Page'}</div>*/}
                {!isDetailed && <>
                    <div className='break-after-block'><div className='break-after'></div></div>
                    <div className='signature-area'>
                        <div className='signature-content'>
                        </div>
                        <div className='signature-section'>
                            <div className='signature-body'>
                                <div className='signature-header'>Observer Signature</div>
                                <div className='signature-sign'>
                                    <div className='signature-line'><div className='signature-desc'>By:</div><div className='signature'><span>{selectedObservation?.ObserverSignature}</span></div></div>
                                    <div className='signature-line'><div className='signature-desc'>Name:</div><div className='signature-name'>{selectedObservation?.ObserverSignature}</div></div>
                                    <div className='signature-line'><div className='signature-desc'>Date:</div><div className='signature-date'>{selectedObservation?.ObserverSignedDate ? FormatUTCDate(selectedObservation?.ObserverSignedDate) : ''}</div></div>
                                    {(!selectedObservation?.ManualSignature) && <div className='signature-not-manual'>Electronically Signed on {FormatUTCDateTime(selectedObservation?.ObserverSignedDate)} </div>}
                                </div>
                            </div>
                            <div className='signature-body'>
                                <div className='signature-header'>Observed Signature</div>
                                <div className='signature-sign'>
                                    <div className='signature-line'><div className='signature-desc'>By:</div><div className='signature'><span>{selectedObservation?.ObservedSignature}</span></div></div>
                                    <div className='signature-line'><div className='signature-desc'>Name:</div><div className='signature-name'>{selectedObservation?.ObservedSignature}</div></div>
                                    <div className='signature-line'><div className='signature-desc'>Date:</div><div className='signature-date'>{selectedObservation?.ObservedSignedDate ? FormatUTCDate(selectedObservation?.ObservedSignedDate) : ''}</div></div>
                                    {(!selectedObservation?.ManualSignature) && <div className='signature-not-manual'>Electronically Signed on {FormatUTCDateTime(selectedObservation?.ObservedSignedDate)} </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </>}
            </div >}
        </PrintLayout >
    </>
    );
}

export default ObservationReviewPrint;