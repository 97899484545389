import _ from "lodash";
import React, { useState } from "react";
import { useEffect } from "react";
import { TableControl } from "../../../../components/controls";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import CheckIcon from '@mui/icons-material/Check';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import moment from 'moment';
import Loading from '../../../../components/Loading';
import { faPlay } from "@fortawesome/free-solid-svg-icons";

const CertificationTeacherList = ({ filteredItems, onEdit, onPrint, resetCertification, handlePassingCertification, manuallySelectCertContent, appUserMgr, resetCertificationQuestions }) => {
    const [printing, setPrinting] = useState(false);
    const [rows, setRows] = useState([]);
    const [actionIsLoading, setActionIsLoading] = useState({ action: '', id: '' })
    const [columns] = useState([
        {
            header: 'Organization,District,School',
            dataProp: 'fullOrg',
            width: '25%',
            canSort: true
        },
        {
            header: 'Certification Candidate',
            dataProp: 'userFullName',
            width: '20%',
            canSort: true
        },
        {
            header: 'Start Date',
            dataProp: 'StartedDate',
            datatype: 'dateTime',
            width: '12%',
            canSort: true
        },
        {
            header: 'Status',
            dataProp: 'Status',
            width: '10%',
            canSort: true
        },
    ]);
    //Custom Editing will only be for Sysadmin, OrgAdmin, Themselves, Nietadmin
    // 
    // Reset, manual pass, manual set content.
    // Hide icon for rubric answers in content.
    //

    const statusIdentifier = (item) => {
        if (item?.Status === 9) {
            return "Manually Passed"
        }
        else if (item?.Status === 0 || item?.Status === 7) {
            return "Not Started"
        } else if (item?.Status === 1) {
            return "Part I In Progress"
        } else if (item?.Status === 2) {
            return "Pass Part 1"
        } else if (item?.Status === 3) {
            return "Fail Part 1"
        } else if (item?.Status === 4) {
            return "Part II In Progress"
        } else if (item?.Status === 5) {
            return "Passed"
        } else if (item?.Status === 6) {
            return "Failed"
        }
    }
    useEffect(() => {
        let newRows = [];
        if (filteredItems) {
            newRows = filteredItems.map((st) => ({
                ...st,
                Status: statusIdentifier(st),
                userFullName: st.User.FullName,
                fullOrg: <>{`${st.OrganizationName ? st.OrganizationName : ''}`}<br />{`${st.DistrictName ? st.DistrictName : ''}`}<br /> {`${st.SchoolName ? st.SchoolName : ''}`}</>,
                StartedDate: moment.utc(st.StartedDate).local()._isValid ? moment.utc(st.StartedDate).local() : ""
            }));
            setRows(newRows);
        }
    }, [filteredItems]);

    //Update this
    const handleCheckDisableAction = (row, action) => {
        //Custom4 is Choosing Content
        if (action.toLowerCase() == 'custom4') {
            return row.Status === 'Manually Passed' || row.Status === 'Passed' && true
        } else if (action.toLowerCase() == 'custom3') {
            return row.Status === 'Passed' || row.Status === 'Manually Passed' || row.Status === 'Pass Part 1'
        }
        // custom2 is resetting part 2 of teacherCert 
        else if (action.toLowerCase() === 'custom2') {
            return row.Status === 'Not Started' || row.Status === 'Part I In Progress' || row.Status === 'Pass Part 1' || row.Status === 'Fail Part 1'
        }
        else if (action.toLowerCase() === 'custom1') {
            return row.Status === 'Not Started'
        }
        else if (action.toLowerCase() === 'print') {
            if (row.Status === "Passed" || row.Status === "Manually Passed") {
                return false
            } else return true
        } else if (action.toLowerCase() == 'custom5') {
            return row.Status !== 'Not Started'
        } else if (action.toLowerCase() == 'edit') {
            if (appUserMgr?.canWrite("CertificationTakeAny") ||
                appUserMgr?.GetGlobalState()?.userProfile.Id === row.UserId) {
                return false
            } else return true
        }
        else {
            return false;
        }
    }

    const checkLoadingAction = (action) => {
        if (action === 'print') {
            return action
        }
    }

    return <>
        <div className="contentDisplay-list">
            <TableControl
                columns={columns}
                data={rows}
                checkDisableAction={handleCheckDisableAction}
                useIntegratedPaging={['10', '25', '50', '100']}
                checkLoadingAction={checkLoadingAction}
                actionIsLoadingCheck={actionIsLoading}
                printing={true}
                onPrint={async (row) => await onPrint(row)}
                onEdit={(row) => onEdit(row)}
                editIcon={faPlay}
                editTitle={'Take Certification'}
                defaultSortBy={'userFullName'}
                defaultSortDesc={'asc'}
                onCustom1={{
                    action: appUserMgr.canExecute('CertificationReset') ? (row) => <div className='icon-wrapper' title="Reset Certification Part 1">
                        <RestartAltIcon className='icon' onClick={() => {
                            if (!handleCheckDisableAction(row, 'custom1')) {
                                resetCertification(row)
                            }
                        }} />
                    </div> : null
                }}
                onCustom2={{
                    action: appUserMgr.canExecute('CertificationReset') ? (row) => <div className='icon-wrapper' title="Reset Certification Part 2">
                        <RestartAltIcon className='icon' onClick={() => {
                            if (!handleCheckDisableAction(row, 'custom2')) {
                                resetCertificationQuestions(row)
                            }
                        }} />
                    </div> : null
                }}
                onCustom3={{
                    action: appUserMgr.canExecute('CertificationManualPass') ? (row) => <div title="Manually Pass Part 1" className='icon-wrapper'>
                        <CheckIcon className='icon' onClick={() => {
                            if (!handleCheckDisableAction(row, 'custom3')) {
                                handlePassingCertification(row)
                            }
                        }} />
                    </div> : null
                }}
                onCustom4={{
                    action: appUserMgr.canExecute('CertificationManualPass') && appUserMgr.canExecute('AzureB2CSaveUserProfile') ? (row) => <div title="Manually Pass Part 2" className='icon-wrapper'>
                        <DoneAllIcon className='icon' onClick={() => {
                            if (!handleCheckDisableAction(row, 'custom4')) {
                                handlePassingCertification(row, true)
                            }
                        }} />
                    </div> : null
                }}
                onCustom5={{
                    action: appUserMgr.canExecute('CertificationChangeContent') ? (row) => <div className='icon-wrapper' title="Select Certification Video">
                        {actionIsLoading.id === row.Id && actionIsLoading.action === 'custom5' ? <Loading type='table-spinner' size={'1.25rem'} /> : <ContentPasteSearchIcon className='icon' onClick={async () => {
                            if (!handleCheckDisableAction(row, 'custom5')) {
                                setActionIsLoading({ action: "custom5", id: row.Id })
                                await manuallySelectCertContent(row).then(() => setActionIsLoading({ action: '', id: '' }))
                            }
                        }} />}
                    </div> : null
                }}

            />
        </div>
    </>
}

export default CertificationTeacherList;