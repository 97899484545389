import React, { useState } from 'react';
import _ from 'lodash';
import moment from "moment";
import { useSurveyContext } from './components/SurveyDataProvider';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ScreenWrapper from '../../components/layout/screenWrapper';
import TeacherSurveyDetails from './components/teachersurvey/details';
import TeacherSurveyList from './components/teachersurvey/table';
import TeacherSurveyFilter from './components/teachersurvey/filter';
import TeacherSurveyResults from './components/teachersurvey/results';
import ButtonControl from '../../components/controls/ButtonControl';
import { DialogControl } from '../../components/controls/DialogControl';
import { InputField } from '../../components/controls/InputField';
import { SelectListControl } from '../../components/controls';
import { TreePicker } from 'rsuite';
import MainLayout from '../../components/layout/MainLayout';
import TeacherSurveyPrintScreen from './components/teachersurvey/printscreen';
import Print from '../observations/components/print';
import { FormatUTCDateTimeLong } from '../../utilities/DateFormater';
import TeacherSurveyPrintEmptyScreen from './components/teachersurvey/printemptysurvey';
import SystemTypes from '../../SystemTypes';

const TeacherSurveys = () => {
    const mgrs = useSurveyContext();
    const { managerIsReady, orgMgr, appUserMgr } = mgrs;
    const surveyMgr = mgrs.teacherSurveyMgr;
    const [manageMode, setManageMode] = useState(null);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null)
    const [openSurveyConfigDialog, setOpenSurveyConfigDialog] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null)
    const [allSchools, setAllSchools] = useState(null)
    const [orgTree, setOrgTree] = useState(null)
    const [saving, setSaving] = useState(false)
    const [printUrl, setPrintUrl] = useState(null);
    const [printEmpty, setPrintEmpty] = useState(null)
    const [printing, setPrinting] = useState(false);
    const [printData, setPrintData] = useState(null)
    const [errors,setErrors] = useState(null);
    const [openDeleteSurveyDialog, setOpenDeleteSurveyDialog] = useState(false);
    const [surveyData, setSurveyData] = useState();
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        // load user tree (always slow - can this be cached in browser?)
        const fetchOrgTreeAsync = async () => orgMgr.loadOrgTree().then(r => setOrgTree(r));

        if (managerIsReady) {
            fetchOrgTreeAsync();
            setLoading(true);

            if (params?.surveyId) {
                handleParamChange();
            }
            else {
                surveyMgr.search(null, true).then(r => {
                    setLoading(false)
                    setManageMode('list')
                });
            }
        }
    }, [managerIsReady, params]);

    useEffect(() => {
        if(surveyMgr) {
            if(surveyMgr.FilteredData)
                setSurveyData(surveyMgr.FilteredData);
        }
    }, [surveyMgr, surveyMgr?.FilteredData])

    const handleParamChange = async (item) => {
        if (item && (_.isNil(item?.SurveySections) || !item.UserId)) {
            setSaving(true)
            const nullOutFields = { ...selectedItem }
            if (nullOutFields.UserId == null) {
                nullOutFields.UserId = surveyMgr.AppUserState.userProfile.Id;
                item.UserId = surveyMgr.AppUserState.userProfile.Id;
            }
            nullOutFields.User = undefined
            nullOutFields.SurveyedUser = undefined
            nullOutFields.SurveyTemplate = undefined
            nullOutFields.School = undefined
            nullOutFields.Name = selectedItem.SurveyTemplateName
            if (!nullOutFields.SurveyStatusType || nullOutFields.SurveyStatusType == 0)
                nullOutFields.SurveyStatusType = 1
            await surveyMgr.save(nullOutFields).then(r => {
                navigate(`/survey/teachersurvey/${r.Items.first().Id}`)
            });
        } else if (item) {
            navigate(`/survey/teachersurvey/${item.Id}`)
        } else {
            surveyMgr.getTeacherAdminSurvey(params?.surveyId).then(r => {
                const surveyItem = r.Items?.first()
                if (location?.pathname.includes('review')) {
                    if (surveyItem.SurveyStatusType == 2 || surveyItem.SurveyStatusType == 3) {
                        setManageMode('viewResult')
                    } else navigate(`/survey/teachersurvey/${surveyItem.Id}`)
                } else if (surveyItem.SurveyStatusType == 3) {
                    navigate(`/survey/teachersurvey/review/${surveyItem.Id}`)
                } else setManageMode('view')
                setData(surveyItem)
                setLoading(false)
            })
        }
    }

    const handleOnFilterChange = async (field, value) => {
        const surveyFilter = surveyMgr.Filter;
        const updatedSurveyFilter = { ...surveyFilter };
        updatedSurveyFilter[field] = value !== '' ? value : null;
        await surveyMgr.filterData(updatedSurveyFilter);
    }

    const handleOnSave = async (item, condition) => {
        const spreadItem = {
            ...item, SurveySections: _.map(item.SurveySections, ss => {
                return {
                    ...ss,
                    Value: Array.isArray(ss.Value) ? ss?.Value?.join(';') : ss?.Value,
                    Options: undefined,
                    SurveyFieldType: undefined,
                }
            })
        }

        var result = await surveyMgr.saveTeacherAdminSurvey(spreadItem, null);
        if(result.length > 0)
        {
            if (result.first() && !condition) {
                navigate(-1)
            } else if (result.first() && condition === 'NEXT') {
                navigate(`/survey/teachersurvey/review/${data?.Id}`)
            } else if (result.first() && condition === 'SUBMIT') {
                navigate(`/survey/teachersurvey`)
            }
        }
        else{
            setSelectedItem(null);
            setErrors(["Failed to save survey. If this problem continues please contact NIET Customer Support"]);
        }
        return result;
    }

    const handleOnEdit = (item) => {
        setSelectedItem(item)
        const formattedSchools = _.map(_.filter(orgMgr.getAllSchools(), as => _.find(item?.SurveyedUser.Schools, x => x.SchoolId === as.SchoolId)), s => { return { Text: s.Name, Value: s.SchoolId, DistrictId: s.DistrictId, ClientId:s.ClientId } })
        setAllSchools(formattedSchools)
        setOpenSurveyConfigDialog(true)
    }

    const handleOnView = (item) => {
        navigate(`/survey/teachersurvey/review/${item?.Id}`)
    }

   
    const handleOnChange = (value, fieldName, obj) => {
        const updatedItem = { ...selectedItem }
        updatedItem[fieldName] = value;
        if(fieldName == 'SchoolId'){
            var school = allSchools.find(x=>x.Value == value);
            if(school){
                updatedItem.DistrictId = school.DistrictId;
                updatedItem.ClientId = school.ClientId;
            }
        }
        else if(fieldName=='UserId'){
            updatedItem.UserPositionType = obj.UserPositionType;
        }
        setSelectedItem(updatedItem)
    }

    const disableParentNodes = (tree) => {
        const disabledParentNodes = []
        const recursiveAdder = (branch) => {
            _.forEach(branch, x => {
                if (x.NodeType.toLowerCase() !== "user") {
                    disabledParentNodes.push(x.Id)
                    recursiveAdder(x.Children)
                }
            })
        }
        recursiveAdder(tree)
        return disabledParentNodes
    }
    const handleOnDuplicate = (item) => {
        const nullOutFields = { ...item }
        nullOutFields.Name = item.SurveyTemplateName
        nullOutFields.SurveyStatusType = 0
        nullOutFields.ExculdeFromOverallScoring = true
        nullOutFields.Id = undefined
        nullOutFields.SurveyId = undefined
        nullOutFields._id = undefined
        nullOutFields.UniqueId = undefined
        nullOutFields.SurveySections = undefined
        nullOutFields.SurveyTemplateName = nullOutFields?.SurveyTemplate?.Title + " (BENCHMARK)"
        handleOnEdit(nullOutFields)
    }

    const handleOnPrintReport = async (survey, location) => {
        if (location === 'review') {
            setPrintData(survey)
        } else {
            await surveyMgr.getTeacherAdminSurvey(survey?.Id).then(r => {
                setPrintData(r.Items?.first())
            })
        }
        setPrinting(true);
        setPrintUrl(`/survey/teachersurvey/review/${survey?.Id}?d=${moment().toISOString(true)}`)
    }
    const printingCompleted = () => {
        setPrinting(false)
        setPrintData(null)
    }
    const handlePrintEmpty = async (survey) => {
        if (_.isNil(survey?.SurveySections)) {
            setSaving(true)
            const nullOutFields = { ...survey }
            nullOutFields.User = undefined
            nullOutFields.SurveyedUser = undefined
            nullOutFields.SurveyTemplate = undefined
            nullOutFields.School = undefined
            nullOutFields.Name = survey.SurveyTemplateName
            nullOutFields.SurveyStatusType = 1
            await surveyMgr.save(nullOutFields).then(r => {
                surveyMgr.getTeacherAdminSurvey(r.Items.first().Id).then(res => {
                    setPrintData(res.Items.first())
                })
            })
        } else {
            const r = await surveyMgr.getTeacherAdminSurvey(survey?.Id);
            setPrintData(r.Items?.first())
        }
        setPrintEmpty(true)
    }

    const handleOnDelete = async (survey) => {
        setSelectedItem(survey);
        setOpenDeleteSurveyDialog(true);
    }

    const deleteSurvey = async (survey) => {
        setSaving(true);
        if(survey) {
            survey.IsDeleted = true;
            survey.IsActive = false;
            if(survey?.SurveySections) {
                survey.SurveySections.forEach(section => {
                    section.IsActive = false;
                    section.IsDeleted = true;
                });
            }
            await surveyMgr.save(survey).then(r => {
                const surveyDataArray = Object.values(surveyData);
                const updatedSurveyDataArray = surveyDataArray.filter((x) => x.Id !== survey.Id);

                const updatedSurveyData = {};
                updatedSurveyDataArray.forEach((item, index) => {
                updatedSurveyData[index + 1] = item;
                });
                setSurveyData(updatedSurveyData);
                setSaving(false);
            })
        }
    }

    return (<>
        <DialogControl title={`Delete Survey?`}
            subTitle={"Please note, by removing this survey, the teacher's final SKR score will be affected."}
            loadingSave={saving} 
            openDialog={openDeleteSurveyDialog}
            okText={`Delete`}
            onCancel={() => {
                setSelectedItem(null)
                setOpenDeleteSurveyDialog(false)
            }}
            onOk={async () => {
                setSaving(true)
                await deleteSurvey(selectedItem)
                setOpenDeleteSurveyDialog(false)
                setSaving(false)
                setSelectedItem(null)
            }}>
        </DialogControl>
        <DialogControl title={`Teacher ${selectedItem?.ExculdeFromOverallScoring ? "Benchmark" : ""} Survey`}
            subTitle={selectedItem?.ExculdeFromOverallScoring ? "" : "Please note by completing this survey, the teacher's final SKR score will be affected."}
            loadingSave={saving} 
            openDialog={openSurveyConfigDialog}
            okText={`${selectedItem?.Status === "Not Started" ? "Begin" : "Continue"}`}
            onCancel={() => {
                setSelectedItem(null)
                setOpenSurveyConfigDialog(false)
            }}
            onOk={async () => {
                setSaving(true)
                await handleParamChange(selectedItem)
                setOpenSurveyConfigDialog(false)
                setSaving(false)
            }}>
            <div className="survey-personnel-bottom-container">
                <InputField
                    title="Teacher Surveyed:"
                    value={selectedItem?.SurveyedTeacher}
                    type='label' />
                <InputField
                    title="Job Title:"
                    value={selectedItem?.UserPosition}
                    type='label' />
            </div>
            <div className="survey-middle-container">
                {
                    selectedItem?.SurveyedUser.Schools?.length === 1 ?
                        <InputField title="School" value={selectedItem?.School?.Name} type='label'
                        />
                        :
                        <InputField title="School" value={selectedItem?.SchoolId ?? "UNK"} fieldName="SchoolId" onChange={handleOnChange}
                        >
                            <SelectListControl textValuePairs={allSchools} disabled={selectedItem?.User || appUserMgr?.AppUserState?.userProfile?.UserPositionType != SystemTypes.UserPositionType.Admin } />
                        </InputField>
                }
            </div>
            <div className="survey-bottom-container">
                <InputField
                    title="Surveyor"
                >
                    <TreePicker
                        height={320}
                        width={400}
                        data={orgTree ?? []}
                        defaultValue={selectedItem?.UserId ? 'u-' + selectedItem?.UserId : 'u-' + surveyMgr?.AppUserState.userProfile.Id}
                        valueKey={"Id"}
                        childrenKey="Children"
                        labelKey={'Name'}
                        disabled={selectedItem?.User || appUserMgr?.AppUserState?.userProfile?.UserPositionType != SystemTypes.UserPositionType.Admin }
                        disabledItemValues={disableParentNodes(orgTree)}
                        // onClean={() => handleOnChange(null, "ClientId")}
                        onSelect={(a, val,obj) => handleOnChange(val.substring(2), 'UserId',a)}
                        placement="bottomStart"
                    />
                </InputField>

            </div>
        </DialogControl>

        {printing && <TeacherSurveyPrintScreen data={printData} printingCompleted={printingCompleted} />}
        {printEmpty && <TeacherSurveyPrintEmptyScreen data={printData} printingCompleted={printingCompleted} />}
        <MainLayout errors={errors}>
            <ScreenWrapper loading={loading} className={' survey-wrapper'} >
                {(manageMode === 'view' || manageMode === 'viewResult')
                    && <>
                        <div className='survey-screen-header'>
                            <div className="survey-header-row">
                                <div className="survey-header-wrapper">
                                    <h1>{data?.Name}</h1>
                                    {/* ONLY SHOW THE COMPLETED STATUS for NIET ADMINS. */}
                                    {data.SurveyStatusType == 3 && <div className="survey-status">SUBMITTED</div>}
                                </div>
                                <div className="survey-subheader-wrapper">
                                    <div className="survey-subheader-left">Survey Given By:</div>
                                    <div className="survey-subheader-right">{data?.User.FullName}</div>
                                </div>
                                <div className="survey-subheader-wrapper">
                                    <div className="survey-subheader-left">Teacher Surveyed:</div>
                                    <div className="survey-subheader-right">{data?.SurveyedUser?.FullName}</div>
                                </div>
                                {data?.School && <div className="survey-subheader-wrapper">
                                    <div className="survey-subheader-left">School:</div>
                                    <div className="survey-subheader-right">{data?.School?.Name}</div>
                                </div>}
                                {/* <div className="survey-subheader-wrapper">
                                    <div className="survey-subheader-left">Start Date:</div>
                                    <div className="survey-subheader-right"> {FormatUTCDateTimeLong(data?.SurveyDate)}</div>
                                </div> */}
                            </div>
                            {manageMode === 'viewResult' && <div className="survey-header-button-wrapper">
                                <ButtonControl type={'cancel'} loading={printing} onClick={() => handleOnPrintReport(data, 'review')}>Print Report</ButtonControl>
                                {data?.SurveyStatusType != 3 && <ButtonControl type={'cancel'} onClick={() => {
                                    navigate(`/survey/teachersurvey/${data.Id}`)
                                }}>EDIT SURVEY</ButtonControl>}
                                <ButtonControl type={'cancel'} onClick={() => {
                                    navigate('/survey/teachersurvey')
                                }}>{data?.SurveyStatusType == 3 ? "EXIT" : "FINISH LATER"}</ButtonControl>
                                {data?.SurveyStatusType == 2 && <ButtonControl type={'okay'} loading={saving} onClick={() => {
                                    const copySurvey = { ...data }
                                    copySurvey.SurveyStatusType = 3
                                    const result = handleOnSave(copySurvey, 'SUBMIT')
                                    setSaving(true)
                                    if (result) {
                                        setSaving(false)
                                    }
                                }}>SUBMIT</ButtonControl>}
                            </div>}
                        </div>
                        {(data && manageMode === 'view') && <TeacherSurveyDetails data={data} saveSurvey={handleOnSave} />}
                    </>
                }
                {manageMode === 'list' &&
                    <div className='control-box-wrapper'>
                        <div className='control-box-list'>
                            <TeacherSurveyFilter surveyMgr={surveyMgr} orgMgr={orgMgr} orgTree={orgTree} onFilterChange={handleOnFilterChange} />
                            <TeacherSurveyList handlePrintEmpty={handlePrintEmpty} appUserMgr={appUserMgr} filteredItems={surveyData ?? []} orgs={orgTree} onEdit={handleOnEdit} onView={handleOnView} onPrint={handleOnPrintReport} onDuplicate={handleOnDuplicate} onDelete={handleOnDelete} />
                        </div>
                        <div className='screen-footer list'>
                        </div>
                    </div>
                }
                {
                    manageMode === 'viewResult' && <TeacherSurveyResults data={data} saveSurvey={handleOnSave} />
                }
            </ScreenWrapper>
        </MainLayout>

    </>
    );
}

export default TeacherSurveys;