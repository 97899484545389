import { ItemServiceFactory } from '../services/ItemServiceFactory';
  
import ItemManager from './ItemManager';
import _ from 'lodash'
import moment from 'moment';
import SystemTypes from '../SystemTypes';
import { msalConfig } from '../configs/authConfig';
import AppUserManager from './AppUserManager';

class ObservationManager extends ItemManager {
  constructor(globalItemState, globalAuthState, globalAppUserState) {
    super(ItemServiceFactory.ItemServiceEnum.Observation, globalAuthState, globalAppUserState, globalItemState,
      (filter, item) => {
        if (filter) {
          const hasOrg = !filter?.organizations || filter?.organizations.length === 0 || _.find(filter?.organizations ?? [], treeId => treeId.indexOf("o-") === 0 && treeId.substr(2).toLowerCase() === item.ClientId.toLowerCase());
          const hasUserObserved = !filter?.userObserved || filter?.userObserved.length === 0 || (filter?.userObserved ?? []).some(treeId => treeId.indexOf("u-") === 0 && treeId.substr(2).toLowerCase() === item.UserObserved?.toLowerCase());
          const hasObserverUser = !filter?.observer || filter?.observer.length === 0 || (filter?.observer ?? []).some(treeId => treeId.indexOf("u-") === 0 && treeId.substr(2).toLowerCase() === item.ObserverUser?.toLowerCase());
          const hasDistrict = !filter?.organizations || filter?.organizations.length === 0 || _.find(filter?.organizations ?? [], treeId => treeId.indexOf("d-") === 0 && treeId.substr(2).toLowerCase() === item.DistrictId.toLowerCase());
          const hasSchool = !filter?.organizations || filter?.organizations.length === 0 || _.find(filter?.organizations ?? [], treeId => treeId.indexOf("s-") === 0 && treeId.substr(2).toLowerCase() === item.SchoolId.toLowerCase());
          const hasStatus = !filter.status || filter.status === 'UNK' || filter?.status == item.ObservationStatusType;
          const hasSubjects = !filter.subject || filter.subject === 'UNK' || item?.SubjectsTaught?.some(x => x.toLowerCase() === filter?.subject.toLowerCase());
          const hasStartDate = !filter.startDate || moment(item.PlannedObservationDateStart).local() > moment(filter?.startDate).local();
          const hasPCStartDate = !filter.pcDate || moment(item.PlannedPostConferanceDate).local() > moment(filter?.pcDate);
          const hasSearch = !filter.search || filter.search == '' || filter.search == item.Id ||
            item.ObservedFullName?.toLowerCase()?.indexOf(filter.search.toLowerCase()) > -1 ||
            `${item.ObservedFirstName?.toLowerCase()} ${item.ObservedLastName?.toLowerCase()}`.indexOf(filter.search.toLowerCase()) > -1 ||
            item.ObservedFirstName?.toLowerCase()?.indexOf(filter.search.toLowerCase()) > -1 ||
            item.ObservedLastName?.toLowerCase()?.indexOf(filter.search.toLowerCase()) > -1 ||
            item.ObservedSystemId?.startsWith(filter.search) || item.ObservedSystemId?.endsWith(filter.search) ||
            item.ObservedEmail?.toLowerCase()?.indexOf(filter.search.toLowerCase()) > 0;
          return (hasOrg || hasDistrict || hasSchool) && hasStatus && hasSubjects && hasPCStartDate && hasStartDate && hasUserObserved && hasObserverUser && hasSearch;
        }
        else
          return true;

      }, async (params) => {
        return await this.runOperation('ObservationList', undefined, undefined, params);
      });

    this._obsConfigSvc = ItemServiceFactory.GetItemService(ItemServiceFactory.ItemServiceEnum.ObservationConfig, globalAuthState);
    this._obsConfigMgr = new ItemManager(ItemServiceFactory.ItemServiceEnum.ObservationConfig, globalAuthState, globalAppUserState);
    this._obsOptionSvc = ItemServiceFactory.GetItemService(ItemServiceFactory.ItemServiceEnum.ObservationOption, globalAuthState);
    this._errorUtil = undefined;

    this._setOptions = (value) => globalItemState.merge({ options: value })
    this._setSelectedObservation = (value) => globalItemState.merge({ selectedObservation: value })
    this.formatStatus = (obsTypeStatus) => ObservationManager.formatStatus(obsTypeStatus);

    this._pendingOptionsPromise = null;
  }


  async observationConfigOperation(orgId, districtId, schoolId, schoolYear) {
    schoolYear = schoolYear ?? this.AppUserState.selectedSchoolYear;
    const observationObj = schoolId ? { oId: orgId, dId: districtId, sId: schoolId, sy: schoolYear } :
      districtId ? { oId: orgId, dId: districtId, sy: schoolYear } :
        orgId ? { oId: orgId, sy: schoolYear } : { sy: schoolYear }

    return this._obsConfigMgr.runOperation('ObservationConfig', undefined, undefined, observationObj).then((r => { return r.Items.first() }))
  }

  get selectedObservation() {
    return this._globalItemState.get({ noproxy: true }).currentObservation;
  }

  async getObservationReviewRatings(observationId) {
    return await this.runOperation('ObservationReviewRatings', observationId);
  }

  async getOptions(optionType) {
    let options = this._globalItemState?.get({ noproxy: true })?.options;
    const clientId = this._globalAppUserState?.get({ noproxy: true })?.userProfile?.ClientId;

    if (!options) {
      if (this._pendingOptionsPromise) {
        await this._pendingOptionsPromise;
        options = this._globalItemState?.get({ noproxy: true })?.options;
      } else {
        this._pendingOptionsPromise = this._obsOptionSvc.list()
          .then(r => {
            if (r.first()?.Success) {
              options = r.first()?.Items;
              this._setOptions(options);
            }
            this._pendingOptionsPromise = null;
            return options;
          })
          .catch(err => {
            this._pendingOptionsPromise = null;
            throw err;
          });
        
        await this._pendingOptionsPromise;
      }
    }

    const filteredOptions = _.filter(options ?? [], (x => !optionType || x.OptionType?.toLowerCase() === optionType?.toLowerCase() && (!x.ClientId || x.ClientId === clientId)));
    return _.orderBy(filteredOptions, x => x.Sequence ?? x.Name);
  }

  static formatAnannounced(observationType) {
    if (observationType === 1) {
      return "No"
    }
    else {
      return "Yes"
    }
  }

  static formatStatus(obsTypeStatus) {
    let status = "";
    switch (obsTypeStatus) {
      case 0:
        status = "Not Started";
        break;
      case 1:
        status = "In Progress";
        break;
      case 2:
        status = "Submitted";
        break;
      case 3:
        status = "Self Reflection Not Started";
        break;
      case 4:
        status = "Self Reflection In Progress";
        break;
      case 5:
        status = "Self Reflection Submitted";
        break;
      case 6:
        status = "Post Conference";
        break;
      case 7:
        status = "Observer Signed";
        break;
      case 8:
        status = "Observed Signed";
        break;
      case 9:
        status = "Completed";
        break;
    };

    return status;
  }

  static formatStatusJSX(obsTypeStatus) {
    let status = "";
    switch (obsTypeStatus) {
      case 0:
        status = "Not Started";
        break;
      case 1:
        status = "In Progress";
        break;
      case 2:
        status = "Submitted";
        break;
      case 3:
        status = <>Self Reflection<br />Not Started</>;
        break;
      case 4:
        status = <>Self Reflection<br /> In Progress</>;
        break;
      case 5:
        status = <>Self Reflection<br /> Submitted</>;
        break;
      case 6:
        status = "Post Conference";
        break;
      case 7:
        status = <>Observer<br /> Signed</>;
        break;
      case 8:
        status = <>Signed<br /> Not Verified</>;
        break;
      case 9:
        status = "Completed";
        break;
    };

    return status;
  }

  transformGridData(apiData) {
    var gridData = {};
    var tagMap = {};

    if (apiData.Rubric?.RubricRatings?.length > 0 && apiData.Rubric.RubricIndicatorRatings) {
      let visitedTags = [];
      //Tag stuff
      apiData.Rubric.RubricIndicatorRatings.forEach(item => {
        if (!visitedTags.includes(item.RubricIndicatorName)) {
          visitedTags.push(item.RubricIndicatorName);
          //This will get taken out when shortname is enforced on tag creation (earlier in flow)
          let manufactoredShortName = item.RubricIndicatorName.split(' ').reduce((r, cv) => {
            r += cv[0];
            return r;
          }, '');
          var abbr = item.RubricIndicatorAbbr ?? manufactoredShortName;
          if (tagMap[abbr]) {
            let abbrUnique = false;
            let i = 1;
            while (!abbrUnique) {
              if (!tagMap[abbr + `${i}`]) {
                abbr = abbr + `${i}`;
                abbrUnique = true;
              }
              else {
                i += 1;
              }
            }
          }
          tagMap[abbr] = { id: item.RubricIndicatorId, name: item.RubricIndicatorName }
        }
      });

      //Grid stuff
      gridData["metaData"] = [];
      gridData["data"] = [];
      apiData.Rubric.RubricRatings.forEach(item => {
        gridData["metaData"].push({
          ratingHeader: item.Title,
          subHeader: item.ShortTitle,
          rating: item.RubricRating,
          rubricRatingId: item.RubricRatingId,
          ratingShowInRubric: item.RatingShowInRubric
        });
      });

      Object.keys(tagMap).forEach(item => {
        gridData["data"].push({
          tag: {
            name: tagMap[item].name,
            abbr: item
          },
          rubric: apiData.Rubric.RubricIndicatorRatings.reduce((r, cv) => {
            if (cv.RubricIndicatorName === tagMap[item].name) {
              r[`${cv.Rating}`] = cv.Description;
            }
            return r;
          }, {}),
          surveyRatingId: apiData.Rubric.RubricIndicatorRatings.reduce((r, cv) => {
            if (cv.RubricIndicatorName === tagMap[item].name) {
              r = cv.SurveyRatingId;
            }
            return r;
          }, null),
          selectedRating: apiData.Rubric.RubricIndicatorRatings.reduce((r, cv) => {
            if (cv.RubricIndicatorName === tagMap[item].name) {
              if (cv.SelectedRating) {
                r = cv.Rating
              }
            }
            return r;
          }, '')
        });
      });
    }
    else { return null; }

    return {
      gridData,
      tagMap
    };
  }

  transformData(apiData, isSelfReflection) {
    var gridData = {};
    var notes = [];
    var tagMap = {};
    var stopwatch = apiData?.SurveySection?.StopWatch ?? 0;
    let rubricData = isSelfReflection ? apiData.SelfReflectionRubric : apiData.Rubric;
    let sectionData = isSelfReflection ? apiData.SelfReflectionSurveySection : apiData.SurveySection;
    if (rubricData?.RubricRatings?.length > 0 && rubricData.RubricIndicatorRatings) {
      let visitedTags = [];
      //Tag stuff
      rubricData.RubricIndicatorRatings.forEach(item => {
        if (!visitedTags.includes(item.RubricIndicatorName)) {
          visitedTags.push(item.RubricIndicatorName);
          //This will get taken out when shortname is enforced on tag creation (earlier in flow)
          let manufactoredShortName = item.RubricIndicatorName.split(' ').reduce((r, cv) => {
            r += cv[0];
            return r;
          }, '');
          var abbr = item.RubricIndicatorAbbr ?? manufactoredShortName;
          if (tagMap[abbr]) {
            let abbrUnique = false;
            let i = 1;
            while (!abbrUnique) {
              if (!tagMap[abbr + `${i}`]) {
                abbr = abbr + `${i}`;
                abbrUnique = true;
              }
              else {
                i += 1;
              }
            }
          }
          tagMap[abbr] = { id: item.RubricIndicatorId, name: item.RubricIndicatorName }
        }
      });

      //Survey/notes stuff
      sectionData?.Sections?.forEach(item => {
        notes.push({
          id: item.SurveySectionId,
          surveySectionId: item.SurveySectionId,
          surveySectionType: item.SurveySectionType,
          time: item.Time,
          sectionTitle: item.SectionTitle,
          evidence: item.Evidence,
          inProgressEvidence: item.InProgressEvidence,
          tags: item.Tags.reduce((r, cv) => {
            if (cv.ContentType === 1) {
              r[cv.Content] = cv.RubricIndicators.map(ri => ri.ShortName);
            }
            return r;
          }, {}),
          tagsServerStruct: item.Tags,
          media: item.Tags.reduce((r, cv) => {
            if (cv.ContentType === 2) {
              r.push({
                SurveyContentId: cv.SurveyContentId,
                FileId: cv.FileId,
                FileUrl: cv.Content,
                FileExtension: cv.FileExtension,
                tags: cv.RubricIndicators.map(ri => ri.ShortName)
              })
            }
            return r;
          }, []),
          isNewNote: item.IsNewNote
        })
      });

      //Grid stuff
      gridData["metaData"] = [];
      gridData["data"] = [];

      _.orderBy(rubricData.RubricRatings, ['RatingSequence'], ['asc']).forEach(item => {
        gridData["metaData"].push({
          ratingHeader: item.Title,
          subHeader: item.ShortTitle,
          rating: item.RubricRating,
          rubricRatingId: item.RubricRatingId,
          ratingSequence: item.RatingSequence,
          ratingShowInRubric: item.RatingShowInRubric
        });
      });

      Object.keys(tagMap).forEach(item => {
        gridData["data"].push({
          tag: {
            name: tagMap[item].name,
            abbr: item
          },
          rubric: rubricData.RubricIndicatorRatings.reduce((r, cv) => {
            if (cv.RubricIndicatorName === tagMap[item].name) {
              r[`${cv.Rating}`] = cv.Description;
            }
            return r;
          }, {}),
          surveyRatingId: rubricData.RubricIndicatorRatings.reduce((r, cv) => {
            if (cv.RubricIndicatorName === tagMap[item].name) {
              r = cv.SurveyRatingId;
            }
            return r;
          }, null),
          selectedRating: rubricData.RubricIndicatorRatings.reduce((r, cv) => {
            if (cv.RubricIndicatorName === tagMap[item].name) {
              if (cv.SelectedRating) {
                r = cv.Rating
              }
            }
            return r;
          }, '')
        });
      });
    }
    else { return null; }

    return {
      gridData,
      notes,
      tagMap,
      stopwatch
    };
  }

  async getObservationSurveyAndRubric(observationId, isSelfReflection, isScriptingPageOnly, isSelfReflectionPageOnly, surveyId, rubricId) {
    let payload = {}
    if (isScriptingPageOnly && surveyId && rubricId) {
      payload.isscripting = true;
      payload.surveyid = surveyId;
      payload.rubricid = rubricId;
    }
    else if (isSelfReflectionPageOnly && surveyId && rubricId) {
      payload.isselfreflection = true;
      payload.surveyid = surveyId;
      payload.rubricid = rubricId;
    }
    else {
      payload.norubric = false;
    }
    return this.runOperation('ObservationSurveyAndRubric', observationId, undefined, payload).then(async r => {

      if (r?.Items?.first()) {
        return await this.transformData(r.Items.first(), isSelfReflection);
      }
      else {
        return null;
      }
    })
  }

  async saveContentRecommendations(observationId, ContentRecommendations, persistRecommendation, forObserved) {
    return await this.runOperation('SaveRecommendedContent', observationId,
      {
        recommendedcontentsaveitem:
        {
          ObservationId: observationId,
          ContentItems: ContentRecommendations,
          ForObserved: forObserved ?? false,
          PersistRecommendation: persistRecommendation ?? false
        }
      })
  }

  static getEnv() {
    if (msalConfig.auth.postLogoutRedirectUri.startsWith('http://localhost:3000')) {
      return 'LOCAL';
    }
    else if (msalConfig.auth.postLogoutRedirectUri.startsWith('https://eepassv2webapp-dev.azureedge.net')) {
      return 'DEV'
    }
    else if (msalConfig.auth.postLogoutRedirectUri.startsWith('https://eepassv2webapp-qa.azureedge.net')) {
      return 'QA'
    }
    else {
      return 'INVALID';
    }
  }

  async getObservationMyDashboard() {
    let SchoolYearIdentifier = this.AppUserState.selectedSchoolYear;
    return await this.runOperation('ObservationMyDashboard', undefined, { sy: SchoolYearIdentifier });
  }

  async saveStopWatch(observationId, stopwatch) {
    return await this.runOperation('SaveSurveyStopWatch', observationId, { stopwatch })
  }

  async emailStatusChange(observationId, observationstatuschange) {
    return await this.runOperation('ObservationStatusChangeNotification', observationId, { observationstatuschange })
  }

  async getObservationSurveySectionsForReview(observationId, excludesurveyratings) {
    if (excludesurveyratings) {
      return await this.runOperation('ObservationSurveyAndRubric', observationId, undefined, { excludesurveyratings: true });
    }
    else {
      return await this.runOperation('ObservationSurveyAndRubric', observationId, undefined, { norubric: true });
    }
  }

  async saveRating(SurveyRatingDTI) {
    return this.runOperation('SurveyRatingSave', null, SurveyRatingDTI);
  }

  async saveObservationNote(observationId, observationNote) {
    return this.runOperation('SaveObservationNote', observationId, observationNote);
  }

  isAdmin(userProfile) {
    return userProfile.UserPositionType === SystemTypes.UserPositionType.Admin;
  }

  hasObserverEntryPermission(userObservedId) {
    //console.log("AppUserManager.checkPermission('ObserverEntry', 'Write', this.AppUserState): ", AppUserManager.checkPermission('ObserverEntry', 'Write', this.AppUserState));
    var canDo = AppUserManager.checkPermission('ObserverEntry', 'Write', this.AppUserState);
    var isSame = this.AppUserState.userProfile.Id !== userObservedId;
    return canDo && isSame;
  }

  canAccessCreate(isTeacher) {
    const userProfile = this.AppUserState?.userProfile;
    if (this.AppUserState?.selectedSchoolYear != this.AppUserState?.currentSchoolYear &&
      !AppUserManager.checkPermission("PastSchoolYearDataMgmt", 'View', this.AppUserState)) {
      return false;
    }
    else {
      if (this.CanWrite &&
        (userProfile?.UserPositionType < SystemTypes.UserPositionType.OrganizationAdmin ||
          (isTeacher && AppUserManager.checkPermission("TeacherCertified", 'View', this.AppUserState)) ||
          (!isTeacher && AppUserManager.checkPermission("SchoolAdminCertified", 'View', this.AppUserState))
        )
      ) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  canAccessReviewObserver(data) {
    const userProfile = this.AppUserState?.userProfile;
    if ((this.CanView && this.isAdmin(userProfile)) || this.hasObserverEntryPermission(data?.UserObserved)) {
      return true;
    }
    return (
      this.CanView &&
      (
        (data.ObservationStatusType > SystemTypes.ObservationStatusType.Submitted && userProfile.Id === data.ObserverUser)
        ||
        (userProfile.UserPositionType < SystemTypes.UserPositionType.Teacher && _.min(userProfile.UserTypes) < SystemTypes.UserType.UserData_Assocated && data.UserObserved !== userProfile.Id && data.ObservationStatusType >= SystemTypes.ObservationStatusType.Submitted)
      )
    );
  }

  canAccessReviewObserved(data) {
    const userProfile = this.AppUserState?.userProfile;
    if ((this.CanView && this.isAdmin(userProfile)) || this.hasObserverEntryPermission(data?.UserObserved)) {
      return true;
    }
    return (
      this.CanView &&
      (data.ObservationStatusType > SystemTypes.ObservationStatusType.SelfReflectionSubmitted)
      &&
      (
        (data.UserObserved === userProfile.Id)
        ||
        (userProfile.UserPositionType < SystemTypes.UserPositionType.MasterTeacher && userProfile.UserTypes.reduce((r, cv) => { if (cv < SystemTypes.UserType.UserData_Assocated) { r = true } return r }, false))
      )
    );
  }

  canAccessReview(data) {
    return this.canAccessReviewObserver(data) || this.canAccessReviewObserved(data);
  }

  canViewRecommendedContent(data) {
    const userProfile = this.AppUserState?.userProfile;
    if ((this.CanView && this.isAdmin(userProfile)) || this.hasObserverEntryPermission(data?.UserObserved)) {
      return true;
    }
    return (
      this.CanView &&
      (
        (data?.ObserverUser === userProfile.Id)
        ||
        (userProfile.UserPositionType < SystemTypes.UserPositionType.MasterTeacher && userProfile.UserTypes.reduce((r, cv) => { if (cv < SystemTypes.UserType.UserData_Assocated) { r = true } return r }, false))
      )
    )
  }

  canAccessScripting(data) {
    const userProfile = this.AppUserState?.userProfile;
    if ((this.CanView && this.isAdmin(userProfile)) || this.hasObserverEntryPermission(data?.UserObserved)) {
      return true;
    }
    if (data.ObservationType < SystemTypes.ObservationType.SchoolLeaderObservation) {
      return (
        this.CanView &&
        (
          (data?.ObserverUser === userProfile.Id && data.ObservationStatusType < SystemTypes.ObservationStatusType.Submitted)
          ||
          (userProfile.UserPositionType < SystemTypes.UserPositionType.Teacher && data?.UserObserved !== userProfile.Id)
        )
      )
    }
    else {
      return (
        this.CanView &&
        (
          (data?.ObserverUser === userProfile.Id && data.ObservationStatusType < SystemTypes.ObservationStatusType.Submitted)
          ||
          (userProfile.UserPositionType < SystemTypes.UserPositionType.MasterTeacher && data?.UserObserved !== userProfile.Id)
        )
      )
    }
  }

  canEditRecommendedContent(data) {
    const userProfile = this.AppUserState?.userProfile;
    if ((this.CanView && this.isAdmin(userProfile)) || this.hasObserverEntryPermission(data?.UserObserved)) {
      return true;
    }
    return (
      this.CanWrite &&
      (
        (data?.ObserverUser === userProfile.Id)
        ||
        (userProfile.UserPositionType < SystemTypes.UserPositionType.MasterTeacher && userProfile.UserTypes.reduce((r, cv) => { if (cv < SystemTypes.UserType.UserData_Assocated) { r = true } return r }, false))
      )
    )
  }

  disableSelfReflection(observationConfig, isTeacher) {
    let disableSelfReflection = false;
    if (observationConfig) {
      disableSelfReflection = (observationConfig.DisableSelfReflectionTeacher && isTeacher) || (observationConfig.DisableSelfReflectionSchoolAdmin && !isTeacher)
    }
    return disableSelfReflection;
  }


  isObservationComplete(data) {
    return data?.ObservationStatusType === SystemTypes.ObservationStatusType.Completed
  }

  canEditScripting(data) {
    const userProfile = this.AppUserState?.userProfile;
    if ((this.CanView && this.isAdmin(userProfile)) || this.hasObserverEntryPermission(data?.UserObserved)) {
      return true;
    } else if (this.isObservationComplete(data)) {
      return false;
    }

    return (this.CanWrite &&
      (
        _.min(userProfile.UserTypes) === 0
        ||
        (data.ObservationStatusType >= 0 && data.ObserverUser === userProfile.Id)
        ||
        (_.min(userProfile.UserTypes) < 99 && data.UserObserved !== userProfile.Id && data.ObservationStatusType >= 0
          && ((data.ObservationType === SystemTypes.ObservationType.SchoolLeaderObservation && data.UserPositionType < SystemTypes.UserPositionType.AssitantSchoolAdmin)
            || (data.ObservationType !== SystemTypes.ObservationType.SchoolLeaderObservation && data.UserPositionType < SystemTypes.UserPositionType.Teacher)
          )
        )
        || (data.ObservationType != SystemTypes.ObservationType.SchoolLeaderObservation && userProfile.UserPositionType < SystemTypes.UserPositionType.AssitantSchoolAdmin) //isTeacher is true and less than assistant principal
      )
    );
  }

  canAccessSelfReflection(data) {
    const userProfile = this.AppUserState?.userProfile;
    if ((this.CanView && this.isAdmin(userProfile)) || this.hasObserverEntryPermission(data?.UserObserved)) {
      return true;
    }
    return (
      this.CanView &&
      data.ObservationStatusType >= SystemTypes.ObservationStatusType.Submitted
      &&
      (
        (
          data.UserObserved === userProfile.Id
        )
      )
    )
  }

  canEditSelfReflection(data) {
    const userProfile = this.AppUserState?.userProfile;
    if ((this.CanView && this.isAdmin(userProfile)) || this.hasObserverEntryPermission(data?.UserObserved)) {
      return true;
    } else if (this.isObservationComplete(data)) {
      return false;
    }
    return (
      this.CanWrite &&
      (
        (data.ObservationStatusType >= SystemTypes.ObservationStatusType.Submitted)
        &&
        (data.UserObserved === userProfile.Id)
      )
    )
  }

  canAccessEdit(data) {
    return this.canEditScripting(data) || this.canEditSelfReflection(data);
  }

  canAccessEvidence(data) {
    const userProfile = this.AppUserState?.userProfile;
    if ((this.CanView && this.isAdmin(userProfile)) || this.hasObserverEntryPermission(data?.UserObserved)) {
      return true;
    }

    return (
      this.CanWrite &&
      (
        (data.UserObserved !== userProfile.Id) &&
        ((data.ObservationType < SystemTypes.ObservationType.SchoolLeaderObservation
          && userProfile.UserPositionType < SystemTypes.UserPositionType.Teacher) ||
          (data.ObservationType == SystemTypes.ObservationType.SchoolLeaderObservation && userProfile.UserPositionType < SystemTypes.UserPositionType.AssitantSchoolAdmin))
      )
    )
  }

  get DefaultItem() {
    return {
      SchoolYearId: null,
      OrganizationId: null,
      DistrictId: null,
      SchoolId: null,
      SelfReflectionSurveyId: null,
      SurveyId: null,
      ObserverUser: null,
      UserObserved: null,
      SelfReflectionType: 1,
      ExculdeFromOverallScoring: false,
      PlannedObservationDateStart: null,
      PlannedObservationTimeEnd: null,
      ObservationType: null,
      SubjectsTaught: [],
      GradeLevels: [],
      PlannedPostConferanceDate: null,
      ActualPostConferanceDate: null,
      ObservationStatusType: SystemTypes.ObservationStatusType.NotStarted,
      ObserverContentRecommendationIds: [],
      ObservedContentRecommendationIds: []
    };
  }
}

export default ObservationManager;